
import { defineComponent, PropType, computed } from 'vue'

// import svg icons
const importAll = ctx => ctx.keys().forEach(ctx)
importAll(require.context('@/assets/icons', false, /\.svg$/))

export default defineComponent({
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String as PropType<string>,
      required: true
    }
  },
  setup(props) {
    const iconName = computed(() => `#icon-${props.iconClass}`)
    return { iconName }
  }
})
