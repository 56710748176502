
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Home',
  setup() {
    const router = useRouter()
    const topRoutes = router.getRoutes().filter(route => route.meta.level === 0)
    if (topRoutes.length > 0) {
      router.replace({ name: topRoutes[0].name })
    }
  }
})
