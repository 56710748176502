import { get, post, request } from '@/utils/http/request'
import type { RequestOptions } from '@/utils/http/types'
import type {
  Permission,
  EnumItemResult,
  PermissionFormData,
  Role,
  FileTemplate,
  SimpleMember,
  MemberParams,
  MemberListResult
} from './model/systemModel'

// 枚举
export function getEnums(key: string) {
  const [type, group] = key.split('/')
  return get<EnumItemResult[]>('sys/enum/list', { type, group })
}

// 超管角色
// ------------------------------------------
// export const getEnablePermissions = (options?: RequestOptions) => {
//   return get<Permission[]>('permission/getEnablePermissionList', {}, options)
// }
export function getRoles(options?: RequestOptions) {
  return get<Role[]>('role', options)
}
export function getRole(id: number, options?: RequestOptions) {
  return get<Role>(`role/${id}`, options)
}
export function addRole(data: Role, options?: RequestOptions) {
  return post('role', data, options)
}
export function updateRole(data: Role, options?: RequestOptions) {
  return request({
    url: 'role',
    method: 'PUT',
    data
  }, options)
}
export function deleteRole(id: number, options?: RequestOptions) {
  return request({
    url: `role/${id}`,
    method: 'DELETE'
  }, options)
}

// 服务系统角色
// ------------------------------------------
export function getServiceRoles(options?: RequestOptions) {
  return get<Role[]>('settle/role/list', options)
}
export function getServiceRole(id: number, options?: RequestOptions) {
  return get<Role>(`settle/role/${id}`, options)
}
export function addServiceRole(data: Role, options?: RequestOptions) {
  return post('settle/role/insert', data, options)
}
export function updateServiceRole(data: Role, options?: RequestOptions) {
  return post('settle/role/update', data, options)
}

// 业务系统角色
// ------------------------------------------
export function getBusinessRoles(options?: RequestOptions) {
  return get<Role[]>('business/role/list', options)
}
export function getBusinessRole(id: number, options?: RequestOptions) {
  return get<Role>(`business/role/${id}`, options)
}
export function addBusinessRole(data: Role, options?: RequestOptions) {
  return post('business/role', data, options)
}
export function updateBusinessRole(data: Role, options?: RequestOptions) {
  return request({
    url: 'business/role',
    method: 'PUT',
    data
  }, options)
}
export function deleteBusinessRole(id: number, options?: RequestOptions) {
  return request({
    url: `business/role/${id}`,
    method: 'DELETE'
  }, options)
}

// 超管权限
// ------------------------------------------
// 获取权限
export function getPermissions() {
  return get<Permission[]>('permission')
}
// 添加权限
export function addPermission(data: PermissionFormData, options?: RequestOptions) {
  return post('permission', data, options)
}
// 编辑权限
export function updatePermission(data: PermissionFormData, options?: RequestOptions) {
  return request({
    url: 'permission',
    method: 'PUT',
    data
  }, options)
}
// 删除权限
export function deletePermission(id: number, options?: RequestOptions) {
  return request({
    url: 'permission/' + id,
    method: 'DELETE'
  }, options)
}
// 启用权限
export function enablePermission(ids: number[], options?: RequestOptions) {
  return post('permission/enable', ids, options)
}
// 禁用权限
export function disablePermission(ids: number[], options?: RequestOptions) {
  return post('permission/disable', ids, options)
}

// 服务系统权限
// ------------------------------------------
export function getServicePermissions() {
  return get<Permission[]>('settle/permission/all')
}
export function addServicePermission(data: PermissionFormData, options?: RequestOptions) {
  return post('settle/permission', data, options)
}
export function updateServicePermission(data: PermissionFormData, options?: RequestOptions) {
  return request({
    url: 'settle/permission',
    method: 'PUT',
    data
  }, options)
}
export function deleteServicePermission(id: number, options?: RequestOptions) {
  return request({
    url: 'settle/permission/' + id,
    method: 'DELETE'
  }, options)
}
export function enableServicePermission(ids: number[], options?: RequestOptions) {
  return post('settle/permission/enable', ids, options)
}
export function disableServicePermission(ids: number[], options?: RequestOptions) {
  return post('settle/permission/disable', ids, options)
}

// 业务系统权限
// ------------------------------------------
export function getBusinessPermissions() {
  return get<Permission[]>('business/permission/all')
}
export function addBusinessPermission(data: PermissionFormData, options?: RequestOptions) {
  return post('business/permission', data, options)
}
export function updateBusinessPermission(data: PermissionFormData, options?: RequestOptions) {
  return request({
    url: 'business/permission',
    method: 'PUT',
    data
  }, options)
}
export function deleteBusinessPermission(id: number, options?: RequestOptions) {
  return request({
    url: 'business/permission/' + id,
    method: 'DELETE'
  }, options)
}
export function enableBusinessPermission(ids: number[], options?: RequestOptions) {
  return post('business/permission/enable', ids, options)
}
export function disableBusinessPermission(ids: number[], options?: RequestOptions) {
  return post('business/permission/disable', ids, options)
}

// 成员管理
// ------------------------------------------
export function getMemberList(params: MemberParams, options?: RequestOptions) {
  return get<MemberListResult>('user/paging', params, options)
}
export function addMember(data: SimpleMember, options?: RequestOptions) {
  return post('user', data, options)
}
export function updateMember(data: SimpleMember, options?: RequestOptions) {
  return request({
    url: 'user',
    method: 'PUT',
    data
  }, options)
}
export function deleteMember(id: number, options?: RequestOptions) {
  return request({
    url: `user/${id}`,
    method: 'DELETE'
  }, options)
}

// 模板
// ------------------------------------------
export function getTemplates() {
  return get<FileTemplate[]>('org/model/list')
}

export function uploadTemplate(id: number, file: File, options?: RequestOptions) {
  const formData = new FormData()
  formData.append('id', String(id))
  formData.append('file', file)
  return post('org/model/upload', formData, options)
}

// 公共枚举列表总和
export function getUnionEnumList(
  options: RequestOptions = {}
) {
  return get('sys/enum/list', options)
}
