/**
 * 校验相关
 */
export function checkMobile(mobile: string): boolean {
  return /^1\d{10}$/g.test(mobile)
}

export function mobileValidator(rule, value: string, callback: (res?) => void) {
  if (checkMobile(value)) {
    callback()
  } else {
    callback(new Error('请填写正确的手机号'))
  }
}
