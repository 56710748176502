<template>
  <div class="app-page block">
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppPage'
})
</script>
<style lang="less" scoped>
@import url("~@/styles/variables.less");

.app-page {
  min-height: calc(100vh - @topbar_height - 10px);

  .third-nav + &,
  .app-tab + & {
    position: relative;
    z-index: 2;
    min-height: calc(100vh - @topbar_height - @third_nav_height - @third_nav_spacing - 10px);
  }
}
</style>
