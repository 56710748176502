<template>
  <el-result
    icon="warning"
    title="加载权限出错"
    sub-title="对不起，加载权限出错，您可以重试"
  >
    <template #extra>
      <el-button
        type="primary"
        :loading="loading"
        @click="load"
      >重试</el-button>
    </template>
  </el-result>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'MenuError',
  setup() {
    const store = useStore()
    const router = useRouter()

    const loading = ref(false)
    const load = async () => {
      loading.value = true
      const res = await store.dispatch('user/updateAsyncRoutes')
      loading.value = false
      if (res) { // 加载成功，跳转到首页
        const topRoutes = router.getRoutes().filter(route => route.meta.level === 0)
        if (topRoutes.length > 0) {
          router.replace({ name: topRoutes[0].name })
        }
      }
    }
    return {
      loading,
      load
    }
  }
})
</script>
