<template>
  <div class="block-title">
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BlockTitle'
})
</script>
<style lang="less" scoped>
.block-title {
  margin: 50px 0;
  font-size: 18px;
}
</style>
