import { RouteRecordRaw } from 'vue-router'
import { AppFrame as Layout, BodyLayout } from '@/layout'

const MessageList = () => import(/* webpackChunkName: "message" */ '@/views/message/MessageList.vue')
const MessageConfig = () => import(/* webpackChunkName: "message" */ '@/views/message/MessageConfig.vue')
const AddMessage = () => import(/* webpackChunkName: "message" */ '@/views/message/AddMessage.vue')
const MessageDetail = () => import(/* webpackChunkName: "message" */ '@/views/message/MessageDetail.vue')

const route: RouteRecordRaw = {
  path: '/message',
  name: 'message',
  component: Layout,
  redirect: { name: 'message_list_list' },
  meta: { title: '消息公告', isMenu: true },
  children: [
    {
      path: 'list',
      name: 'message_list',
      component: BodyLayout,
      redirect: { name: 'message_list_list' },
      meta: { title: '消息列表', isMenu: true },
      children: [
        {
          path: 'list',
          name: 'message_list_list',
          component: MessageList,
          meta: { title: '消息列表' }
        },
        {
          path: 'add',
          name: 'message_list_add',
          component: AddMessage,
          meta: { title: '新增消息', formTypeQuery: 'id' }
        },
        {
          path: 'detail',
          name: 'message_list_detail',
          component: MessageDetail,
          meta: { title: '预览消息' }
        }
      ]
    },
    {
      path: 'confing',
      name: 'message_confing',
      component: BodyLayout,
      meta: { title: '消息配置', isMenu: true },
      redirect: { name: 'message_confing_config' },
      children: [
        {
          path: 'config',
          name: 'message_confing_config',
          component: MessageConfig,
          meta: { title: '消息配置' }
        }
      ]
    }
  ]
}

export default route
