import type { ActionContext } from 'vuex'
import type { AppStore } from '../types'
import { getEnums } from '@/api/system'

export type Enum = Map<string, string>
export type Enums = Map<string, Enum>
export interface SystemState {
  enums: Enums
}

type IActionContext = ActionContext<SystemState, AppStore>

const state: SystemState = {
  enums: new Map()
}

const mutations = {
  setEnum(state: SystemState, enumeration: Record<string, Enum>): void {
    for (const p in enumeration) {
      state.enums.set(p, enumeration[p])
    }
  }
}

const actions = {
  async getEnum(
    { state, commit }: IActionContext,
    key: string,
    forceUpdate = false
  ): Promise<Enum> {
    if (forceUpdate || !state.enums[key]) {
      const res = await getEnums(key)
      if (res.success && res.result) {
        const enumeration: Enum = new Map()
        res.result.forEach(({ dataKey, dataValue }) => {
          enumeration.set(dataKey, dataValue)
        })
        commit('setEnum', { [key]: enumeration })
      }
    }
    return state.enums.get(key) || new Map()
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
