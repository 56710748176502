/**
 * 权限控制
 */
import store from '@/store'
import router, { checkRoutesGenerated } from '@/router'
import { getAuthToken } from '@/utils/auth-token'

// 枚举状态数据，每次登陆或者刷新页面重新获取,存到store
async function setEnumDataUnionOne () {
  const res = await store.dispatch('enumData/setEnumDataUnionOne')
}

// 是否已经生成路由
router.beforeEach(async (to, from, next) => {
  // 有权限或者无需权限
  if ((to.meta.authNotRequired && to.name !== 'NotFound') || getAuthToken() || to.path === '/login') {
    // 已经加载路由，或无需加载路由
    if (checkRoutesGenerated() || to.path === '/login' || to.name === 'MenuError') {
      next()
    } else { // 加载路由
      const res = await store.dispatch('user/updateAsyncRoutes')
      setEnumDataUnionOne() // 获取枚举数据
      if (res) {
        const { path, query } = to
        next({ path, query, replace: true }) // 跳转。重新开始路由鉴权
      } else {
        next({ name: 'MenuError' })
      }
    }
  } else { // 无权限
    next({
      name: 'Login',
      query: {
        redirect: encodeURIComponent(to.fullPath)
      }
    })
  }
})
