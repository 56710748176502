/**
 * 提取日期元素
 */
const chars = ['M', 'd', 'H', 'm', 's']

type IDate = string | number | Date

/**
 * 提取时间的年月日时分秒
 * @param date
 * @returns
 */
export function resolveDate(date: IDate) {
  if (typeof date === 'string') {
    date = date.replace(/-/g, '/')
  }
  if (!(date instanceof Date)) {
    date = new Date(date)
  }
  const resolve: Record<string, number | string> = {
    YY: date.getFullYear(),
    M: date.getMonth() + 1,
    d: date.getDate(),
    H: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds()
  }

  chars.forEach(key => {
    let value = resolve[key]
    if (value < 10) {
      value = '0' + value
    }
    resolve[key + key] = value
  })

  // 毫秒数
  const s3 = date.getTime() % 1000
  if (s3 < 10) {
    resolve.SSS = '00' + s3
  } else if (s3 < 100) {
    resolve.SSS = '0' + s3
  } else {
    resolve.SSS = String(s3)
  }
  resolve.S = resolve.SSS.substr(0, 1)
  resolve.SS = resolve.SSS.substr(0, 2)
  return resolve
}

/**
 * 格式化日期
 * @param date 日期对象
 * @param pattern 格式. 其中D为今天/昨天/前天
 * @returns
 */
export function dateFormat(date: IDate, pattern = 'YY-MM-dd HH:mm:ss'): string {
  const resolvedDate = resolveDate(date)
  if (pattern.indexOf('D') > -1) {
    const today = resolveDate(new Date())
    if (typeof resolvedDate.d === 'number') {
      if (today.d === resolvedDate.d) {
        resolvedDate.D = '今天'
      } else if (today.d === resolvedDate.d + 1) {
        resolvedDate.D = '昨天'
      } else if (today.d === resolvedDate.d + 2) {
        resolvedDate.D = '前天'
      } else {
        pattern = pattern.replace(/D/g, 'YY-MM-dd')
      }
    }
  }
  // 将键由长到短排序，以便先替换长的占位符，防止长的占位符被短的替换掉
  const keys = Object.keys(resolvedDate).sort(
    (a, b) => a.length < b.length ? 1 : -1
  )
  let rs = pattern
  keys.forEach(key => {
    const regex = new RegExp(key, 'g')
    rs = rs.replace(regex, resolvedDate[key] as string)
  })
  return rs
}

/**
* 将日期转换为 xxxx-xx-xx 格式
*/
export function toRegularDate(date: IDate): string {
  return dateFormat(date, 'YY-MM-dd')
}

/**
* 将日期转换为 xxxx年xx月xx 格式
*/
export function toChineseDate(date: IDate): string {
  return dateFormat(date, 'YY年MM月dd日')
}
