
import { defineComponent, ref } from 'vue'
import TheLogo from '@/components/TheLogo.vue'
import AppNav from '@/components/AppNav.vue'

export default defineComponent({
  name: 'AppSidebar',
  components: { TheLogo, AppNav },
  setup() {
    const isCollapse = ref(false)
    const toggleCollapse = () => {
      isCollapse.value = !isCollapse.value
    }
    return { isCollapse, toggleCollapse }
  }
})
