<template>
  <div class="logo">
    <img src="@/assets/images/logo.png" alt="" class="logo__img">
    <span class="logo__title">超管后台</span>
  </div>
</template>
<style lang="less" scoped>
@import url("~@/styles/variables.less");

.logo {
  line-height: @topbar_height;
  color: #fff;
  text-align: center;

  &__img {
    width: 30px;
    height: 30px;
    vertical-align: middle;
  }

  &__title {
    display: inline-block;
    margin-left: 8px;
    font-size: 20px;
    vertical-align: middle;
  }
}
</style>
