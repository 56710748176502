<template>
  <div class="frame-sidebar">
    <app-sidebar />
  </div>
  <div class="frame-main">
    <div class="frame-header">
      <app-header />
    </div>
    <div class="frame-header-placeholder"></div>
    <div class="frame-body">
      <router-view/>
    </div>
  </div>
</template>
<script lang="ts">
import AppHeader from './AppHeader.vue'
import AppSidebar from './AppSidebar.vue'

export default {
  name: 'AppFrame',
  components: { AppHeader, AppSidebar }
}
</script>
<style lang="less" scoped>
@import url("~@/styles/variables.less");

.frame-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: @sidebar_width;
  height: 100%;
  overflow-y: auto;
}

.frame-header {
  position: fixed;
  top: 0;
  left: @sidebar_width;
  right: 0;
  z-index: 99;
}
.frame-header-placeholder {
  height: @topbar_height;
}

.frame-main {
  margin-left: @sidebar_width;
}

.frame-body {
  margin-top: 10px;
}
</style>
