<template>
  <div class="search-bar" :class="{ 'search-bar--row': $slots.side }">
    <template v-if="$slots.side">
      <div class="search-bar__main">
        <slot />
      </div>
      <div class="search-bar__side">
        <slot name="side" />
      </div>
    </template>
    <slot v-else />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SearchBar'
})
</script>
<style lang="less" scoped>
.search-bar {
  margin: 20px 0;

  &--row {
    display: flex;
  }

  &__main {
    flex: 1;
  }

  :deep(.el-form) {
    margin-bottom: -10px;
    font-size: 12px;
  }
  :deep(.el-form-item) {
    margin-bottom: 10px;
    // 360�����������
    &:before, &:after{
      display: block;
    }
  }
  :deep(.el-form-item__label) {
    font-size: 12px;
  }
}
</style>
