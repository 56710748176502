<template>
  <div class="pagination">
    <el-pagination
      :current-page="currentPage"
      :total="total"
      :page-size="pageSize"
      :default-current-page="defaultCurrentPage"
      :default-page-size="defaultPageSize"
      :layout="layout"
      :disabled="disabled"
      background
      @update:currentPage="$emit('update:currentPage', $event)"
      @size-change="$emit('size-change', $event)"
      @current-change="$emit('current-change', $event)"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'AppPagination',
  props: {
    currentPage: {
      type: Number as PropType<number>,
      default: 1
    },
    defaultCurrentPage: {
      type: Number as PropType<number>,
      default: 1
    },
    pageSize: {
      type: Number as PropType<number>,
      default: 10
    },
    defaultPageSize: {
      type: Number as PropType<number>,
      default: 10
    },
    total: {
      type: Number as PropType<number>,
      default: 0
    },
    disabled: Boolean as PropType<boolean>,
    layout: {
      type: String as PropType<string>,
      default: '->, sizes, prev, pager, next, total'
    }
  }
})
</script>
<style lang="less" scoped>
.pagination {
  margin: 20px -15px 0 0;
  text-align: center;
}
</style>
