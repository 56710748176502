
import { defineComponent } from 'vue'
import SubNav from '@/components/SubNav.vue'
import TheUser from '@/components/TheUser.vue'
import AppBreadcrumb from '@/components/AppBreadcrumb.vue'

export default defineComponent({
  name: 'AppHeader',
  components: { SubNav, TheUser, AppBreadcrumb }
})
