<template>
  <div class="sidebar" :class="{ 'sidebar--collapse': isCollapse }">
    <the-logo />
    <nav class="menu">
      <app-nav class="menu-item">
        <template #item="{ item }">
          <span class="menu-item__icon" v-if="item.meta?.icon">
            <svg-icon :icon-class="item.meta.icon" />
          </span>
          {{ item.meta?.title }}
        </template>
      </app-nav>
    </nav>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import TheLogo from '@/components/TheLogo.vue'
import AppNav from '@/components/AppNav.vue'

export default defineComponent({
  name: 'AppSidebar',
  components: { TheLogo, AppNav },
  setup() {
    const isCollapse = ref(false)
    const toggleCollapse = () => {
      isCollapse.value = !isCollapse.value
    }
    return { isCollapse, toggleCollapse }
  }
})
</script>
<style lang="less" scoped>
@import url("~@/styles/variables.less");

.sidebar {
  box-sizing: border-box;
  position: relative;
  height: 100%;
  padding-bottom: 30px;
  background-color: @menu_color;
}

:deep(.menu-item) {
  display: block;
  padding-left: 20px;
  line-height: 40px;
  color: #fff;

  &:not(:first-of-type) {
    margin-top: 14px;
  }

  &:hover {
    background-color: #2F3341;
  }

  &.router-link-active {
    background-color: @brand_primary;
  }

  &__icon {
    display: inline-block;
    margin-right: 10px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
