<template>
  <header class="hd">
    <app-breadcrumb />
    <nav class="hd__navbar">
      <sub-nav />
    </nav>
    <the-user class="hd__user" />
  </header>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import SubNav from '@/components/SubNav.vue'
import TheUser from '@/components/TheUser.vue'
import AppBreadcrumb from '@/components/AppBreadcrumb.vue'

export default defineComponent({
  name: 'AppHeader',
  components: { SubNav, TheUser, AppBreadcrumb }
})
</script>
<style lang="less" scoped>
@import url("~@/styles/variables.less");
.hd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 @horizontal_spacing_base;
  width: 100%;
  height: @topbar_height;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(170, 170, 170, 0.25);

  &__user {
    float: right;
  }
}
</style>
