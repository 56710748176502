
import { defineComponent, PropType, toRef, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'AppNav',
  props: {
    level: {
      type: Number as PropType<number>,
      default: 0
    },
    class: String as PropType<string>,
    fillQueryString: Boolean as PropType<boolean>,
    hideSingleItem: Boolean as PropType<boolean> // 只有单个菜单是否显示
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()

    const level = toRef(props, 'level')
    const className = toRef(props, 'class')

    const routes = computed(() => {
      const routes = router.getRoutes()
      if (routes) {
        return (
          level.value === 0
            ? routes.filter(r => r.meta?.level === 0).filter(r => r.meta?.isMenu)
            : route.matched[level.value - 1].children.filter(r => r.meta?.isMenu)
        )
      }
      return routes
    })

    const visible = computed(() => (
      !props.hideSingleItem || routes.value.length > 1
    ))

    return {
      routes,
      className,
      visible
    }
  }
})
