
import { defineComponent } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  name: 'AppBreadcrumb',
  setup() {
    const route = useRoute()
    // 过滤面包屑，三级和四级的情况，去掉倒数第二个路由
    const getFilteredBread = (routeList) => {
      let breadcrumbList = routeList
      if (routeList && routeList.length >= 3) {
        breadcrumbList = routeList.filter((item, index) => {
          return index !== routeList.length - 2
        })
      }
      return breadcrumbList
    }
    // 页面跳转
    const router = useRouter()
    const goPage = (route) => {
      router.push({
        name: route.name
      })
    }

    const getTitle = (meta) => {
      let { title } = meta
      if ('formTypeQuery' in meta) {
        const query = route.query[meta.formTypeQuery]
        if (query) {
          title = title.replace('新增', '编辑')
        } else {
          title = title.replace('编辑', '新增')
        }
      }
      return title
    }
    return {
      getFilteredBread,
      goPage,
      getTitle
    }
  }
})
