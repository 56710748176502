/**
 * 内置动态路由
 */
import { RouteRecordRaw } from 'vue-router'

// 权限为空（超管后台该用户没有勾选任何菜单）
import NoMenu from '@/views/NoMenu.vue'

const noMenuRoute: RouteRecordRaw = {
  path: '/no-menu',
  component: NoMenu
}

export default noMenuRoute
