import { RouteRecordRaw } from 'vue-router'
import { AppFrame as Layout } from '@/layout'

const Docs = () => import(/* webpackChunkName: "docs" */ '@/views/docs/Docs.vue')

const route: RouteRecordRaw = {
  name: 'docs',
  path: '/docs',
  component: Layout,
  redirect: '/docs/index',
  meta: { title: '文档管理' },
  children: [
    {
      name: 'docs_index',
      path: 'index',
      component: Docs,
      meta: { title: '文档管理' }
    }
  ]
}

export default route
