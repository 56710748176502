<template>
  <div class="navbar">
    <app-nav :level="1" hide-single-item class="nav-item" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import AppNav from './AppNav.vue'

export default defineComponent({
  name: 'SubNav',
  components: { AppNav }
})
</script>
<style lang="less" scoped>
@import url("~@/styles/variables.less");
.navbar {
  :deep(.nav-item) {
    display: inline-block;
    padding: 10px;
    color: @color_base;

    & + & {
      margin-left: 50px;
    }

    &.router-link-active {
      color: @brand_primary;
    }
  }
}
</style>
