<template>
  <div class="page-header">
    <div class="page-header__main">
      <div class="page-header__title">
        <slot />
      </div>
    </div>
    <div class="page-header__side" v-if="$slots.side">
      <slot name="side" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PageHeader'
})
</script>
<style lang="less" scoped>
.page-header {
  display: flex;
  align-items: center;
  min-height: 56px;
  margin: 0 -30px 20px;
  padding: 0 30px;
  border-bottom: 1px solid #eee;

  &__main {
    flex: 1;
  }
}
</style>
