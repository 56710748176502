/**
 * Token相关操作
 */
const tokenKey = 'authorization'

export function getAuthToken() {
  return sessionStorage.getItem(tokenKey)
}

export function setAuthToken(token: string): void {
  sessionStorage.setItem(tokenKey, token)
}

export function removeAuthToken(): void {
  sessionStorage.removeItem(tokenKey)
}
