<template>
  <div class="login-page">
    <div class="login-box">
      <h2 class="login-box__title">薪福多超管系统</h2>
      <div class="login-form">
        <el-form
          ref="loginForm"
          :model="form"
          :rules="rules"
          size="medium"
          @submit.prevent="handleSubmit"
        >
          <el-form-item prop="mobile" :rules="mobileRules">
            <el-input
              ref="mobileRef"
              v-model.trim="form.mobile"
              type="text"
              maxlength="11"
              placeholder="请输入手机号码"
              @blur="clearApiValidator"
            />
          </el-form-item>
          <el-form-item prop="authCode" :rules="autoCodeRules">
            <div class="countdown-group">
              <el-input
                v-model.trim="form.authCode"
                type="text"
                maxlength="6"
                placeholder="请输入验证码"
                @blur="clearApiValidator"
              />
              <verification-code-button
                class="verification-btn"
                :send="sendVerificationCode"
                :disabled="isMobileValid"
              />
            </div>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              native-type="submit"
              style="width: 100%"
              :loading="loading"
            >登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, computed, onMounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import VerificationCodeButton from '@/components/VerificationCodeButton.vue'
import type { User } from '@/store/modules/user'
import { login, getVerificationCode } from '@/api/permission'
import { checkMobile, mobileValidator } from '@/utils/validation'

function decodeUrl(url: string): string {
  const decoded = decodeURIComponent(url)
  return decoded === url ? decoded : decodeUrl(decoded)
}

function getRedirect(raw: string): string {
  const url = decodeUrl(raw)
  if (url.indexOf('/login') === 0) {
    const res = /[&?]redirect=([^&]+)/g.exec(url)
    if (res) {
      return getRedirect(res[1])
    }
  }
  return url
}

const blankValidator = (rule, value, callback) => {
  callback(new Error(''))
}

export default defineComponent({
  name: 'Login',
  components: { VerificationCodeButton },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const form = reactive({
      mobile: '',
      authCode: ''
    })

    // 电话号码是否正确
    const isMobileValid = computed(() => !checkMobile(form.mobile))

    // 发送验证码
    const sendVerificationCode = async () => {
      const res = await getVerificationCode({ mobile: form.mobile })
      return res.success
    }

    // 表单
    // -----------------------------------------------
    const loginForm = ref()

    const apiError = ref('')

    // 校验规则
    const mobileRules = computed(() => {
      const rules: any[] = [
        { required: true, message: '手机号不能为空' },
        { validator: mobileValidator, message: '请填写正确的手机号', trigger: 'blur' }
      ]
      if (apiError.value.indexOf('手机号') > -1) {
        rules.push({
          validator: blankValidator,
          message: apiError.value,
          trigger: 'blue'
        })
      }
      return rules
    })
    const autoCodeRules = computed(() => {
      const rules: any[] = [
        { required: true, message: '验证码不能为空' },
        {
          min: 6,
          max: 6,
          message: '必须为6位验证码',
          trigger: 'blur'
        }
      ]
      if (apiError.value.indexOf('验证码') > -1) {
        rules.push({
          validator: blankValidator,
          message: apiError.value,
          trigger: 'blue'
        })
      }
      return rules
    })

    const clearApiValidator = () => {
      apiError.value = ''
    }

    // 提交
    const handleSubmit = () => {
      loginForm.value.validate(valid => valid && submit())
    }

    // 发送请求
    const loading = ref(false)
    const submit = async () => {
      loading.value = true
      const res = await login(form, { handleError: false })
      loading.value = false
      if (res.success && res.result) {
        const loginResult = res.result
        const {
          id,
          userName,
          orgId,
          orgName,
          createdAt,
          isAdmin,
          isDelete
        } = loginResult
        const user: User = {
          id,
          userName,
          orgId,
          orgName,
          createdAt,
          isAdmin: isAdmin !== 0,
          isDelete: isDelete !== 0
        }
        store.dispatch('user/setUser', user)
        const redirect = (
          route.query.redirect
            ? getRedirect(route.query.redirect as string)
            : ''
        )
        router.replace(
          redirect && redirect.indexOf('/login') !== 0
            ? redirect
            : '/'
        )
      } else if (res.message) {
        if (res.message.indexOf('手机号') > -1 || res.message.indexOf('验证码') > -1) {
          apiError.value = res.message
          nextTick(() => loginForm.value.validate())
        } else {
          ElMessage.error(res.message)
        }
      } else {
        ElMessage.error('请求出错')
      }
    }

    // 退出登录
    store.commit('user/clearUser')

    // 自动获取光标
    const mobileRef = ref()
    onMounted(() => {
      mobileRef.value.focus()
    })

    return {
      form,
      isMobileValid,
      sendVerificationCode,
      loginForm,
      mobileRules,
      autoCodeRules,
      clearApiValidator,
      handleSubmit,
      loading,
      mobileRef
    }
  }
})
</script>
<style lang="less" scoped>
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:
    url("~@/assets/images/bg.png")
    no-repeat
    center center / cover;
}

.login-box {
  width: 500px;
  margin: auto;
  padding: 50px 0 60px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 19px 39px 0 rgba(39,42,48,.25);

  &__title {
    margin: 0 0 50px;
    font-size: 26px;
    text-align: center;
  }
}
.login-form {
  width: 70%;
  margin: auto;
}

.countdown-group {
  display: flex;
}
.verification-btn {
  min-width: 110px;
  margin-left: 10px;
  padding-left: 0;
  padding-right: 0;
}
</style>
