
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'AppPagination',
  props: {
    currentPage: {
      type: Number as PropType<number>,
      default: 1
    },
    defaultCurrentPage: {
      type: Number as PropType<number>,
      default: 1
    },
    pageSize: {
      type: Number as PropType<number>,
      default: 10
    },
    defaultPageSize: {
      type: Number as PropType<number>,
      default: 10
    },
    total: {
      type: Number as PropType<number>,
      default: 0
    },
    disabled: Boolean as PropType<boolean>,
    layout: {
      type: String as PropType<string>,
      default: '->, sizes, prev, pager, next, total'
    }
  }
})
