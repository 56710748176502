import { get, post } from '@/utils/http/request'
import type { RequestOptions } from '@/utils/http/types'
import type { LoginResult, AsyncRoute } from './model/permissionModel'

// 登录
export function login(
  data: { mobile: string, authCode: string },
  options: RequestOptions = {}
) {
  options.useQueryString = true
  return post<LoginResult>('auth/login', data, options)
}

// 获取验证码
export function getVerificationCode(
  params: { mobile: string },
  options?: RequestOptions
) {
  return get('auth/code', params, options)
}

// 获取动态路由
export function getAsyncRoutes() {
  return get<AsyncRoute[]>('auth/getMenu')
}
