import { defineAsyncComponent, App } from 'vue'
import { dateFormat } from '@/utils/date'

import SvgIcon from '@/components/SvgIcon.vue'
import SearchBar from '@/components/SearchBar.vue'
import AppPagination from '@/components/AppPagination.vue'
import AppTable from '@/components/AppTable.vue'
import AppPage from '@/components/AppPage.vue'
import ThirdNav from '@/components/ThirdNav.vue'
import PageHeader from '@/components/PageHeader.vue'
import BlockTitle from '@/components/BlockTitle.vue'
import FluidTable from '@/components/FluidTable'
import TableActions from '@/components/TableActions'

const DistrictSelect = () => import(/* webpackChunkName: 'districtSelect' */ '@/components/DistrictSelect')

export function setupGlobals(app: App<Element>): void {
  // Components
  // -----------------------------------------
  app.component(SvgIcon.name, SvgIcon)
  app.component(SearchBar.name, SearchBar)
  app.component(AppPagination.name, AppPagination)
  app.component(AppTable.name, AppTable)
  app.component(AppPage.name, AppPage)
  app.component(ThirdNav.name, ThirdNav)
  app.component(PageHeader.name, PageHeader)
  app.component(BlockTitle.name, BlockTitle)
  app.component(DistrictSelect.name, defineAsyncComponent(DistrictSelect))
  app.component(FluidTable.name, FluidTable)
  app.component(TableActions.name, TableActions)

  // Filters
  // -----------------------------------------
  app.config.globalProperties.$filters = {
    dateFormat
  }
}

export {
  SvgIcon,
  SearchBar,
  AppPagination,
  AppTable,
  AppPage,
  ThirdNav,
  PageHeader,
  BlockTitle,
  DistrictSelect,
  FluidTable,
  TableActions
}
