import type { AppStore } from './types'
import type { Enums } from './modules/system'
import type { User } from './modules/user'

// 公共getter

export default {
  enums(store: AppStore): Enums {
    return store.system.enums
  },
  user(store: AppStore): User | undefined {
    return store.user.userInfo
  }
}
