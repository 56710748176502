/**
 * 将字符串转换为大驼峰格式
 * eg:
 * michael > Michael
 * michaelJordan > MichaelJordan
 * michael jordan > MichaelJordan
 * michael-jordan > MichaelJordan
 * michael_jordan > MichaelJordan
 */
export function toUpperCamelCase(str: string): string {
  str = str.trim().replace(/([A-Z])/g, ' $1').replace(/[_-]/g, ' ')
  const chars = str.split(/\s+/).map(
    word => word.length <= 1
      ? word.toUpperCase()
      : word[0].toUpperCase() + word.substr(1)
  )
  return chars.join('')
}

export function formatNumber(str: string): string {
  const num = Number(str)
  if (Number.isNaN(num)) {
    str = ''
  }
  return str
}

export function getCharWidth(char: string, fontSize = 12) {
  if (char <= '~') {
    return 0.6 * fontSize
  }
  return fontSize
}

// 获取字符串对应的宽度
export function getStringWidth(str: string | number, fontSize = 12) {
  str = String(str)
  return str
    .split('')
    .reduce(
      (total, char) => Math.ceil(total + getCharWidth(char, fontSize)),
      0
    )
}
