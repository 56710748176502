/**
 * enum Store
 */
import { getUnionEnumList } from '@/api/system'

// 包装枚举数据
const filterData = (list) => {
  const _data = {}
  list.forEach(item => {
    if (!Object.prototype.hasOwnProperty.call(_data, item.dataType)) {
      _data[item.dataType] = {}
    }
    _data[item.dataType][item.dataKey] = item
  })
  return _data
}
// enum store
// -------------------------------------------

const state = {
  // 枚举数据
  enumInfo: {}
}

const getters = {
  enumInfo (state) {
    return state
  }
}

const mutations = {
  setEnumData(state, info) {
    state.enumInfo = info
  }
}

const actions = {
  async setEnumDataUnionOne ({ commit }) {
    const res = await getUnionEnumList()
    if (res.success && res.result) {
      commit('setEnumData', filterData(res.result))
    } else {
      return false
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
