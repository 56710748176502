<template>
  <div class="third-nav block">
    <app-nav :level="level" class="nav-item" :fill-query-string="fillQueryString" />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import AppNav from './AppNav.vue'

export default defineComponent({
  name: 'ThirdNav',
  components: { AppNav },
  props: {
    level: {
      type: Number as PropType<number>,
      default: 2
    },
    fillQueryString: Boolean as PropType<boolean>
  }
})
</script>
<style lang="less" scoped>
@import url("~@/styles/variables.less");
.third-nav {
  display: flex;
  margin-bottom: @third_nav_spacing;
  padding: 0 30px;
  font-size: 14px;

  :deep(.nav-item) {
    position: relative;
    margin-right: 40px;
    line-height: @third_nav_height;
    cursor: pointer;

    &.router-link-active {
      color: @brand_primary;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 40px;
        height: 2px;
        margin-left: -20px;
        background-color: @brand_primary;
      }
    }
  }
}
</style>
