<template>
  <el-table class="fluid-table" border :data="rows">
    <el-table-column
      v-for="(field, index) in fields"
      :type="field.type"
      :key="field.prop"
      :prop="field.prop"
      :label="field.label"
      :width="field.width"
      :min-width="field.minWidth || widthSet[index]"
      :fixed="field.fixed"
      :show-overflow-tooltip="field.showOverflowTooltip"
    >
      <template v-if="!field.type" #default="{ row, column, $index }">
        <slot
          v-if="$slots[field.prop]"
          :name="field.prop"
          :row="data && data[$index]"
          :column="column"
          :$index="$index"
        />
        <template v-else>{{ row[column.property] }}</template>
      </template>
    </el-table-column>
  </el-table>
</template>
<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { getStringWidth } from '@/utils/string'
import { Field } from './types'

export default defineComponent({
  name: 'FluidTable',
  props: {
    fields: Array as PropType<Field[]>,
    data: Array as PropType<any[]>
  },
  setup(props) {
    const rows = computed(() => (
      props.data
        ? props.data.map(row => {
          row = { ...row }
          props.fields && props.fields.forEach(({ prop, formatter }) => {
            if (formatter) {
              row[prop] = formatter(row, { property: prop })
            }
          })
          return row
        })
        : []
    ))

    const widthSet = computed<number[]>(() => {
      if (!props.fields) {
        return []
      }
      const fieldPropsCount = props.fields.length || 0
      const res: number[] = props.fields.map(field => getStringWidth(field.label || '')) || []

      const listLength = rows.value.length
      for (let i = 0; i < listLength; i++) {
        const row = rows.value[i]
        for (let j = 0; j < fieldPropsCount; j++) {
          const { prop: field, getWidth } = props.fields[j]
          const val: string = row[field] || ''
          const width = getWidth ? getWidth(props.data && props.data[i], { property: field }, i) : getStringWidth(val)
          if (width > res[j]) {
            res[j] = width
          }
        }
      }
      return res.map(item => item + 22)
    })

    return {
      rows,
      widthSet
    }
  }
})
</script>
