<template>
  <div class="user">
    <img
      src="@/assets/images/avatar.jpg"
      alt=""
      class="user__avatar"
    >
    <span class="user__organization">{{ user && user.userName }}</span>
    <a
      :href="redirect"
      title="退出登录"
      class="user__logout-button"
    >
      <svg-icon icon-class="logout" />
    </a>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'TheUser',
  setup() {
    const store = useStore()
    const user = store.getters.user

    const redirect = '/login?redirect=' + encodeURIComponent(location.href.replace(location.origin, ''))

    return {
      user,
      redirect
    }
  }
})
</script>
<style lang="less" scoped>
@import url("~@/styles/variables.less");
.user {
  display: flex;
  align-items: center;

  &__avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  &__organization {
    margin-right: 5px;
    font-weight: bold;
  }

  &__logout-button {
    margin-left: 20px;
    color: @color_base;
  }
}
</style>
