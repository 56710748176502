import type { RouteRecordRaw } from 'vue-router'
import type { AsyncRoute } from '@/api/model/permissionModel'
import router from '@/router'
import noMenuRoute from '@/router/modules/menu'

// 所有动态路由
const modulesFiles = require.context('@/router/modules', true, /\.ts$/)
const moduleRoutes = modulesFiles.keys().map(
  modulePath => modulesFiles(modulePath).default
)

export function mergeRoutes(
  asyncRoutes: AsyncRoute[],
  appRoutes: RouteRecordRaw[] = moduleRoutes,
  level = 0
) {
  for (let i = 0, l = appRoutes.length; i < l; i++) {
    const route = appRoutes[i]
    const asyncRoute = asyncRoutes.find(r => r.permissionCode === route.name)
    if (asyncRoute) {
      const { permissionUrl: url } = asyncRoute
      // 设置url
      if (url && url.indexOf('/') < 0) {
        route.path = url
      }
      if (!route.meta) {
        route.meta = {}
      }
      const { meta } = route
      meta.id = asyncRoute.id
      meta.parentId = asyncRoute.parentId
      meta.title = asyncRoute.permissionName
      meta.order = asyncRoute.sort
      meta.permissionType = asyncRoute.permissionType
      if (meta.isMenu !== false) {
        meta.isMenu = asyncRoute.permissionType === 1
      }
      meta.icon = asyncRoute.icon
      meta.level = level

      // 设置子路由
      if (route.children) {
        mergeRoutes(
          asyncRoutes,
          route.children,
          level + 1
        )
      }
    } else if (!(route && route.meta && route.meta.authNotRequired)) { // 无授权
      // 删除路由
      appRoutes.splice(i--, 1)
      l--
    }
  }
  appRoutes.sort((a, b) => {
    const orderA = parseInt(a.meta?.order as string) || 0
    const orderB = parseInt(b.meta?.order as string) || 0
    return orderA - orderB
  })
  return appRoutes
}
export function generateRoutes(asyncRoutes: AsyncRoute[]) {
  const routes = mergeRoutes(asyncRoutes)
  // 空记录，则添加占位路由，以便区分路由加载失败的情况
  if (routes.length === 0) {
    routes.push(noMenuRoute)
  }
  routes.forEach(router.addRoute)
  return routes
}
