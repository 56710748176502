<template>
  <el-button
    :type="type"
    :size="size"
    :icon="icon"
    :native-type="nativeType"
    :plain="plain"
    :round="round"
    :circle="circle"
    :autofocus="autofucus"
    :disabled="state.sending || state.inCountdown || disabled"
    :loading="state.sending"
    @click="handleClick"
  >
    <countdown
      v-if="state.inCountdown"
      ref="countdown"
      :time="time * 1000"
      @finish="handleFinish"
    >
      <template #="{ resolved }">
        <slot name="sending" :resolved="resolved">
          {{ resolved.ss }}秒后重试
        </slot>
      </template>
    </countdown>
    <template v-else>
      <slot :isFirstCountdown="state.isFirstCountdown">
        {{ state.isFirstCountdown ? '获取验证码' : '重新获取' }}
      </slot>
    </template>
  </el-button>
</template>
<script lang="ts">
import {
  defineComponent,
  PropType,
  nextTick,
  ref,
  reactive
} from 'vue'
import Countdown from 'vue3-countdown'

export default defineComponent({
  name: 'VerificationCodeButton',
  components: { Countdown },
  emits: ['click', 'finish'],
  props: {
    // 按钮相关属性
    // ---------------------------------
    type: {
      type: String as PropType<string>,
      default: 'primary'
    },
    size: String as PropType<string>,
    icon: String as PropType<string>,
    nativeType: String as PropType<string>,
    plain: Boolean as PropType<boolean>,
    round: Boolean as PropType<boolean>,
    circle: Boolean as PropType<boolean>,
    autofucus: Boolean as PropType<boolean>,
    disabled: Boolean as PropType<boolean>,

    // 倒计时相关属性
    // ---------------------------------
    // 倒计时长。秒
    time: {
      type: Number as PropType<number>,
      default: 60
    },
    beforeSend: Function as PropType<() => boolean | Promise<boolean>>,
    send: Function as PropType<() => Promise<boolean>>
  },
  setup(props, { emit }) {
    const countdown = ref()
    const state = reactive({
      sending: false,
      inCountdown: false,
      isFirstCountdown: true
    })
    const send = async () => {
      // 检查
      if (props.beforeSend) {
        const p = props.beforeSend()
        const res: boolean = p instanceof Promise ? await p : p
        if (!res) {
          return
        }
      }

      // 发送验证码
      if (props.send) {
        state.sending = true
        const res = await props.send()
        state.sending = false
        if (!res) {
          return
        }
      }

      // 开启倒计时
      state.inCountdown = true
      state.isFirstCountdown = false
      nextTick(() => countdown.value.start())
    }

    const handleClick = (e: MouseEvent) => {
      emit('click', e)
      send()
    }

    // 倒计时结束
    const handleFinish = () => {
      state.inCountdown = false
      emit('finish')
    }

    return {
      countdown,
      state,
      handleFinish,
      handleClick
    }
  }
})
</script>
