import type { App } from 'vue'
import {
  ElButton,
  ElInput,
  ElInputNumber,
  ElCheckbox,
  ElCheckboxGroup,
  ElCheckboxButton,
  ElRadio,
  ElRadioGroup,
  ElRadioButton,
  ElSelect,
  ElOption,
  ElSwitch,
  ElUpload,
  ElForm,
  ElFormItem,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElTable,
  ElTableColumn,
  ElPagination,
  ElDatePicker,
  ElDialog,
  ElCascader,
  ElTag,
  ElLoading,
  ElDivider,
  ElPopconfirm,
  ElTooltip,
  ElResult
} from 'element-plus'

export function setupElementUI(app: App<Element>): void {
  // 默认尺寸
  app.config.globalProperties.$ELEMENT = {
    size: 'small'
  }
  app
    .use(ElButton)
    .use(ElInput)
    .use(ElInputNumber)
    .use(ElCheckbox)
    .use(ElCheckboxGroup)
    .use(ElCheckboxButton)
    .use(ElRadio)
    .use(ElRadioGroup)
    .use(ElRadioButton)
    .use(ElSelect)
    .use(ElOption)
    .use(ElSwitch)
    .use(ElDatePicker)
    .use(ElCascader)
    .use(ElUpload)
    .use(ElForm)
    .use(ElFormItem)
    .use(ElDropdown)
    .use(ElDropdownMenu)
    .use(ElDropdownItem)
    .use(ElBreadcrumb)
    .use(ElBreadcrumbItem)
    .use(ElTable)
    .use(ElTableColumn)
    .use(ElPagination)
    .use(ElDialog)
    .use(ElPopconfirm)
    .use(ElTag)
    .use(ElLoading)
    .use(ElDivider)
    .use(ElTooltip)
    .use(ElResult)
}
