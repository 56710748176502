import { RouteRecordRaw } from 'vue-router'
import { AppFrame as Layout } from '@/layout'

const Payments = () => import(/* webpackChunkName: "payments" */ '@/views/payments/Payments.vue')

const route: RouteRecordRaw = {
  path: '/payments',
  name: 'payments',
  component: Layout,
  redirect: { name: 'payments_payments' },
  meta: { title: '支付管理' },
  children: [
    {
      path: '',
      name: 'payments_payments',
      component: Payments,
      meta: { title: '支付管理' }
    }
  ]
}

export default route
