<template>
  <div class="not-found" />
</template>
<style lang="less" scoped>
.not-found {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(~@/assets/images/404.png) no-repeat center center;
}
</style>
