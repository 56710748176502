<template>
  <div class="app-table">
    <el-table border stripe :height="height" ref="table">
      <slot />
    </el-table>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppTable',
  data() {
    return {
      height: window.innerHeight - 240
    }
  },
  mounted() {
    // const windowHeight = window.innerHeight
    // const c = this.$el.getBoundingClientRect()
    // this.height = windowHeight - c.top
    // console.log(this.height)
    this.height = 100;
    (this.$refs.table as any).doLayout()
  }
})
</script>
<style lang="less" scoped>
.app-table {
  margin: 20px 0;
}
</style>
