/**
 * User Store
 */
import type { ActionContext } from 'vuex'
import type { AppStore } from '../types'
import type { AsyncRoute } from '@/api/model/permissionModel'
import { getAsyncRoutes } from '@/api/permission'
import { generateRoutes } from '@/utils/async-router'
import { removeAuthToken } from '@/utils/auth-token'
import { checkRoutesGenerated } from '@/router'

// 用户类型，从登录接口导入
export interface User {
  id: number
  userName: string
  orgId: number
  orgName: string
  createdAt: number
  isAdmin: boolean
  isDelete: boolean
}

// 用户模块类型
export interface UserState {
  userInfo?: User
  asyncRoutes?: AsyncRoute[]
}

// user模块的action别名
type IActionContext = ActionContext<UserState, AppStore>

// user store
// -------------------------------------------

let asyncRoutesLoading = false

const state: UserState = {}

const mutations = {
  setUser(state: UserState, user: User): void {
    state.userInfo = user
  },
  setAsyncRoutes(state: UserState, asyncRoutes: AsyncRoute[]) {
    state.asyncRoutes = asyncRoutes
  },
  clearUser(state: UserState) {
    delete state.userInfo
    delete state.asyncRoutes
    removeAuthToken()
  }
}

const actions = {
  setUser({ commit }: IActionContext, user: User): void {
    commit('setUser', user)
  },
  removeUser({ commit }: IActionContext): void {
    commit('setUser', null)
  },

  // 更新路由
  async updateAsyncRoutes({ state, commit }: IActionContext, force = false) {
    // 先加载路由
    if ((force || !state.asyncRoutes) && !asyncRoutesLoading) {
      asyncRoutesLoading = true
      const res = await getAsyncRoutes()
      asyncRoutesLoading = false
      if (res.success && res.result) {
        commit('setAsyncRoutes', res.result)
      } else {
        return false
      }
    }
    if (!checkRoutesGenerated() && state.asyncRoutes) {
      generateRoutes(state.asyncRoutes)
    }
    return true
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
