<template>
  <svg class="svg-icon" :class="iconClass" aria-hidden="true">
    <use :xlink:href="iconName" />
  </svg>
</template>
<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'

// import svg icons
const importAll = ctx => ctx.keys().forEach(ctx)
importAll(require.context('@/assets/icons', false, /\.svg$/))

export default defineComponent({
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String as PropType<string>,
      required: true
    }
  },
  setup(props) {
    const iconName = computed(() => `#icon-${props.iconClass}`)
    return { iconName }
  }
})
</script>
<style lang="less" scoped>
.svg-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    cursor: pointer;
    z-index: 1;
  }
}
</style>
