import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory, RouteMeta } from 'vue-router'
import { AppFrame as Layout } from '@/layout'
import NotFound from '@/views/NotFound.vue'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import MenuError from '@/views/MenuError.vue'

// 路由元数据
export interface IRouteMeta extends RouteMeta {
  title: string
  hasAuth: boolean
  generated: false
  authNotRequired?: boolean // 是否不需要登录权限
  id?: number
  parentId?: number
  icon?: string
  sort?: number
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: { name: 'HomeIndex' },
    children: [
      {
        name: 'HomeIndex',
        path: '',
        component: Home,
        meta: {
          title: '首页'
        }
      }
    ]
  },
  {
    name: 'Login',
    path: '/login',
    component: Login,
    meta: {
      title: '登录'
    }
  },
  {
    name: 'MenuError',
    path: '/menu-error',
    component: MenuError,
    meta: {
      title: '路由错误'
    }
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: NotFound,
    meta: {
      title: '页面找不到',
      authNotRequired: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 是否已经初始化路由
let routesGenerated = false
export const checkRoutesGenerated = () => {
  if (!routesGenerated) {
    routesGenerated = router.getRoutes().length > 5
  }
  return routesGenerated
}

export default router
