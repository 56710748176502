import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { setupElementUI, setupGlobals } from './plugins'
import './rem-flexible'
import './permission'
import './styles/element-plus/icon.scss'
import './styles/common.less'

createApp(App)
  .use(store)
  .use(router)
  .use(setupElementUI)
  .use(setupGlobals)
  .mount('#app')
