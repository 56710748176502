
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'TheUser',
  setup() {
    const store = useStore()
    const user = store.getters.user

    const redirect = '/login?redirect=' + encodeURIComponent(location.href.replace(location.origin, ''))

    return {
      user,
      redirect
    }
  }
})
