import { RouteRecordRaw } from 'vue-router'
import { AppFrame as Layout, BodyLayout } from '@/layout'

const ServicerList = () => import(/* webpackChunkName: "servicer" */ '@/views/servicer/servicer/ServicerList.vue')
const ServicerEdit = () => import(/* webpackChunkName: "accounts" */ '@/views/servicer/servicer/ServicerEdit.vue')
const AccountList = () => import(/* webpackChunkName: "accounts" */ '@/views/servicer/account/AccountList.vue')
const AccountDetail = () => import(/* webpackChunkName: "accounts" */ '@/views/servicer/account/AccountDetail.vue')
const ConfigList = () => import(/* webpackChunkName: "accounts" */ '@/views/servicer/servicer/ConfigList.vue')
const PlaceholderList = () => import(/* webpackChunkName: "accounts" */ '@/views/servicer/servicer/PlaceholderList.vue')
const PlaceholderEdit = () => import(/* webpackChunkName: "accounts" */ '@/views/servicer/servicer/PlaceholderEdit.vue')

const route: RouteRecordRaw = {
  path: '/servicer',
  name: 'servicer',
  component: Layout,
  redirect: { name: 'servicer_servicer_list' },
  meta: { title: '服务管理' },
  children: [
    {
      path: 'servicer',
      name: 'servicer_servicer',
      redirect: { name: 'servicer_servicer_list' },
      component: BodyLayout,
      meta: { title: '服务管理', isMenu: true },
      children: [
        {
          path: 'list',
          name: 'servicer_servicer_list',
          component: ServicerList,
          meta: { title: '服务管理', authNotRequired: true }
        },
        {
          path: 'edit',
          name: 'servicer_servicer_edit',
          component: ServicerEdit,
          meta: { title: '服务编辑', authNotRequired: true, formTypeQuery: 'id' }
        }
      ]
    },
    {
      path: 'account',
      name: 'servicer_account',
      component: BodyLayout,
      redirect: { name: 'servicer_account_list' },
      meta: { title: '账户管理', isMenu: false },
      children: [
        {
          path: 'list',
          name: 'servicer_account_list',
          component: AccountList,
          meta: { title: '账户管理', authNotRequired: true }
        },
        {
          path: 'detail',
          name: 'servicer_account_detail',
          component: AccountDetail,
          meta: { title: '账户管理', authNotRequired: true }
        }
      ]
    },
    {
      path: 'config',
      name: 'servicer_config',
      redirect: { name: 'servicer_config_list' },
      component: BodyLayout,
      meta: { title: '配置管理', isMenu: true },
      children: [
        {
          path: 'list',
          name: 'servicer_config_list',
          component: ConfigList,
          meta: { title: '配置管理列表' }
        },
        {
          path: 'placeholder',
          name: 'servicer_config_placeholderList',
          component: PlaceholderList,
          meta: { title: '占位符列表' }
        },
        {
          path: 'placeholderEdit',
          name: 'servicer_config_placeholderEdit',
          component: PlaceholderEdit,
          meta: { title: '占位符编辑', formTypeQuery: 'id' }
        }
      ]
    }
  ]
}

export default route
