import { RouteRecordRaw } from 'vue-router'
import { AppFrame as Layout, BodyLayout } from '@/layout'

const Accounts = () => import(/* webpackChunkName: "accounts" */ '@/views/accounts/accounts/Accounts.vue')
const AccountEdit = () => import(/* webpackChunkName: "accounts" */ '@/views/accounts/accounts/AccountEdit.vue')
const Products = () => import(/* webpackChunkName: "accounts" */ '@/views/accounts/products/Products.vue')
const ProductForm = () => import(/* webpackChunkName: "accounts" */ '@/views/accounts/products/ProductForm.vue')

const route: RouteRecordRaw = {
  path: '/accounts',
  name: 'accounts',
  component: Layout,
  redirect: { name: 'accounts_accounts_accounts' },
  meta: { title: '账户管理' },
  children: [
    {
      path: 'accounts',
      name: 'accounts_accounts',
      redirect: { name: 'accounts_accounts_accounts' },
      component: BodyLayout,
      meta: { title: '账户管理' },
      children: [
        {
          path: '',
          name: 'accounts_accounts_accounts',
          component: Accounts,
          meta: { title: '账户管理', authNotRequired: true }
        },
        {
          path: 'edit',
          name: 'accounts_accounts_edit',
          component: AccountEdit,
          meta: { title: '账户管理', authNotRequired: true }
        }
      ]
    },
    {
      path: 'products',
      name: 'accounts_products',
      component: BodyLayout,
      redirect: { name: 'accounts_products_products' },
      meta: { title: '产品管理' },
      children: [
        {
          path: '',
          name: 'accounts_products_products',
          component: Products,
          meta: { title: '产品管理', authNotRequired: true }
        },
        {
          path: 'product',
          name: 'accounts_products_product',
          component: ProductForm,
          meta: { title: '产品管理', authNotRequired: true }
        }
      ]
    }
  ]
}

export default route
