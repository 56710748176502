<template>
  <a
    v-for="item in outsideActions"
    :key="item.label"
    href="javascript:void(0)"
    class="table-link-button"
    :class="item.sign ? { [`is-${item.sign}`]: true } : null"
    @click="item.action(row)"
  >{{ item.label }}</a>
  <el-dropdown
    v-if="dropdownActions"
    class="table-link-button"
    @command="handleMore(row, $event)"
  >
    <span>更多操作</span>
    <template #dropdown>
      <el-dropdown-menu class="table-dropdown">
        <el-dropdown-item
          v-for="item in dropdownActions"
          :key="item.label"
          :command="item.label"
        >{{ item.label }}</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ActionItem } from './types'

export default defineComponent({
  name: 'TableActions',
  props: {
    actions: Array as PropType<ActionItem[]>,
    row: Object as PropType<any>,
    ellipsisCount: {
      type: Number as PropType<number>,
      default: 3
    }
  },
  computed: {
    outsideActions() {
      return (
        this.actions && this.actions.length > this.ellipsisCount
          ? this.actions.slice(0, this.ellipsisCount - 1)
          : this.actions
      )
    },
    dropdownActions() {
      return (
        this.actions && this.actions.length > this.ellipsisCount
          ? this.actions.slice(this.ellipsisCount - 1)
          : null
      )
    }
  },
  methods: {
    handleMore(row, label) {
      if (this.actions) {
        const current = this.actions.find(item => item.label === label)
        if (current) {
          current.action(row)
        }
      }
    }
  }
})
</script>
<style lang="less" scoped>
@import url("~@/styles/variables.less");

.is-danger {
  color: @color_danger;
}
</style>
