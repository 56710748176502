import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import type { AppStore } from './types'
import * as modules from './modules'
import getters from './getters'

export default createStore<AppStore>({
  modules,
  getters,
  plugins: [
    // 将user存储至localStorage
    createPersistedState({
      paths: ['user.userInfo', 'user.asyncRoutes', 'enumData'],
      storage: sessionStorage
    })
  ]
})
