
import { defineComponent, PropType } from 'vue'
import { ActionItem } from './types'

export default defineComponent({
  name: 'TableActions',
  props: {
    actions: Array as PropType<ActionItem[]>,
    row: Object as PropType<any>,
    ellipsisCount: {
      type: Number as PropType<number>,
      default: 3
    }
  },
  computed: {
    outsideActions() {
      return (
        this.actions && this.actions.length > this.ellipsisCount
          ? this.actions.slice(0, this.ellipsisCount - 1)
          : this.actions
      )
    },
    dropdownActions() {
      return (
        this.actions && this.actions.length > this.ellipsisCount
          ? this.actions.slice(this.ellipsisCount - 1)
          : null
      )
    }
  },
  methods: {
    handleMore(row, label) {
      if (this.actions) {
        const current = this.actions.find(item => item.label === label)
        if (current) {
          current.action(row)
        }
      }
    }
  }
})
