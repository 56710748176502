
import { defineComponent, PropType } from 'vue'
import AppNav from './AppNav.vue'

export default defineComponent({
  name: 'ThirdNav',
  components: { AppNav },
  props: {
    level: {
      type: Number as PropType<number>,
      default: 2
    },
    fillQueryString: Boolean as PropType<boolean>
  }
})
