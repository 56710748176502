import { RouteRecordRaw } from 'vue-router'
import { AppFrame as Layout, BodyLayout } from '@/layout'

const Menu = () => import(/* webpackChunkName: "system" */ '@/views/system/menu/Menu.vue')
const Templates = () => import(/* webpackChunkName: "system" */ '@/views/system/templates/Templates.vue')
const Role = () => import(/* webpackChunkName: "system" */ '@/views/system/roles/Role.vue')
const RoleEdit = () => import(/* webpackChunkName: "system" */ '@/views/system/roles/RoleEdit.vue')
const Member = () => import(/* webpackChunkName: "system" */ '@/views/system/members/Member.vue')

const route: RouteRecordRaw = {
  path: '/system',
  name: 'system',
  component: Layout,
  redirect: { name: 'system_menu' },
  meta: { title: '系统管理' },
  children: [
    {
      path: 'menu',
      name: 'system_menu',
      component: Menu,
      meta: { title: '权限管理' }
    },
    {
      path: 'templates',
      name: 'system_templates',
      component: BodyLayout,
      redirect: { name: 'system_templates_templates' },
      meta: { title: '模板管理', authNotRequired: true },
      children: [
        {
          path: '',
          name: 'system_templates_templates',
          component: Templates,
          meta: { title: '模板管理', authNotRequired: true }
        }
      ]
    },
    {
      path: 'roles',
      name: 'system_roles',
      component: BodyLayout,
      redirect: { name: 'system_roles_roles' },
      meta: { title: '角色管理' },
      children: [
        {
          path: '',
          name: 'system_roles_roles',
          component: Role,
          meta: { title: '角色管理', authNotRequired: true }
        },
        {
          path: 'edit',
          name: 'system_roles_edit',
          component: RoleEdit,
          meta: { title: '角色管理', authNotRequired: true }
        }
      ]
    },
    {
      path: 'members',
      name: 'system_members',
      component: BodyLayout,
      redirect: { name: 'system_members_members' },
      meta: { title: '成员管理' },
      children: [
        {
          path: '',
          name: 'system_members_members',
          component: Member,
          meta: { title: '成员管理', authNotRequired: true }
        }
      ]
    }
  ]
}

export default route
